import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

import { trackGTMEvent } from "lib/utils";

import type { ContentSubscriptionLevelType } from "lib/types/rankings";
import { IS_CI } from "lib/constants";
import type { SubscriptionLevel } from "lib/types";
import type { OnboardingCustomField } from "lib/types/onboarding";
import { DecodedTacCookie, MandatoryDataType, OptionalDataType } from "membership-ui/login/types";
import { updateCustomFields } from "lib/fetchers/piano/customFields";
import { getMembershipLevel } from "components/piano/piano";


export const getPianoUserId = (): Promise<string | null> => {
  const executeAfterPianoInit = (fn: () => void) => {
    window.tp = window.tp || [];
    window.tp.push(["init", fn]);
  };

  return new Promise((resolve) => {
    executeAfterPianoInit(() => {
      const pianoIdUser = window.tp?.pianoId?.getUser()?.uid;
      if (pianoIdUser) {
        return resolve(pianoIdUser.uid);
      } else {
        return resolve(null);
      }
    });
  });
};

export const getPianoUserEmail = (): Promise<string | null> => {
  const executeAfterPianoInit = (fn: () => void) => {
    window.tp = window.tp || [];
    window.tp.push(["init", fn]);
  };

  return new Promise((resolve) => {
    executeAfterPianoInit(() => {
      const userEmail = window.tp?.pianoId?.getUser()?.email;
      if (userEmail) {
        return resolve(userEmail);
      } else {
        return resolve(null);
      }
    });
  });
};

export const setLoggedInCookies = async (
  accessToken: string,
  expiresIn: number
) => {
  const expiresInDays = expiresIn / 60 / 60 / 24;

  Cookies.set("__utp", accessToken, {
    expires: expiresInDays,
    path: "/",
    sameSite: "lax",
    domain:
      process.env.NODE_ENV === "development" || IS_CI
        ? "localhost"
        : ".sifted.eu",
  });

  const [userId, membershipLevel] = await Promise.all([
    getPianoUserId(),
    getMembershipLevel(),
  ]);

  trackGTMEvent({
    event: "login",
    userId,
  });

  const membershipCookieValue = {
    member: "mem",
    startup: "startup",
    pro: "pro",
    // If the reader is not 'member', 'startup' or 'pro' after logging in they
    // are simply marked as registered with no additional access
    free: "reg",
  }[membershipLevel];

  Cookies.set("sMemLev", membershipCookieValue, {
    expires: expiresInDays, // make sure cookie has the same TTL as __utp cookie
    path: "/",
  });
};

export const isMissingInfo = async (loginCookie: string): Promise<boolean> => {
  if (!loginCookie) return Promise.resolve(true);

  return await new Promise((resolve, _reject) => {
    if (typeof window !== "undefined") {
      window.tp = window.tp || [];
      window.tp.push([
        "init",
        function () {
          window.tp.pianoId.loadExtendedUser({
            extendedUserLoaded: function (data) {
              const fields = data.custom_field_values.map((field) => {
                return [field.field_name, field.value];
              });

              const profileData = Object.fromEntries(fields);

              const mandatoryData: Partial<MandatoryDataType> = {
                companyType: profileData.form_company_type,
                companyName: profileData.form_company_name,
                companySize: profileData.form_company_size,
                roleAtBusiness: profileData.form_role_at_business,
              }

              const optionalData: Partial<OptionalDataType> = {
                jobTitle: profileData.form_job_title,
                department: profileData.form_department,
                country: profileData.form_country,
                companyHqCountry: profileData.form_company_hq_country,
                b2bOrB2c: profileData.form_b2b_or_b2c,
              }

              const companyServices = profileData.form_company_type === "Professional Services or Tech Provider";
              const companyInvestment = profileData.form_company_type === "Investment Firm";
              const companyStartupOrScaleup = profileData.form_company_type === "Startup" || profileData.form_company_type === "Scaleup";

              if (companyServices) {
                mandatoryData.professionalServiceType = data.form_professional_service_type
              }
              if (companyInvestment) {
                mandatoryData.investmentFirmType = data.form_investment_firm_type
              }

              if (companyStartupOrScaleup) {
                optionalData.companySector = profileData.form_sector;
                optionalData.companyFounded = profileData.form_company_founded;
                optionalData.latestFundingStage = profileData.form_latest_funding_stage;
              }

              if (!companyStartupOrScaleup) {
                optionalData.companySectors = profileData.form_sectors;
              }

              if (companyInvestment) {
                optionalData.fundingStagesInvested = profileData.form_funding_stages_invested;
                optionalData.aum = profileData.form_aum;
              }

              if (!companyStartupOrScaleup && !companyInvestment) {
                optionalData.workInStartupEcosystem = profileData.form_work_in_startup_ecosystem;
              }

              const checkIfEmpty = (el) => {
                // Dealing with how piano returns data, returning booleans if empty
                if (el === "[]" || !el) return false;
                return true;
              }

              const areMandatoryFieldsFilled = Object.values(mandatoryData).map(
                checkIfEmpty
              ).every(Boolean);
              const areOptionalFieldsFilled = Object.values(optionalData).map(
                checkIfEmpty
              ).every(Boolean);

              const now = new Date();
              const lastAsked = profileData.form_last_asked_for_optional_data
              const lastDataUpdate = lastAsked ? new Date(Number(lastAsked)) : null

              // check if it has been a month since the last update
              const isMonthSinceAsked = lastDataUpdate ? (now.getTime() - lastDataUpdate.getTime()) / (1000 * 3600 * 24) > 30 : true;

              if ((!areOptionalFieldsFilled && isMonthSinceAsked) || !areMandatoryFieldsFilled) {
                resolve(true);
              } else {
                resolve(false);
              }
            },
            formName: "RegistrationFields",
          });
        },
      ]);
    }
  });
};

export const isToVerify = async (): Promise<boolean> => {
  return await new Promise((resolve, _reject) => {
    if (typeof window !== "undefined") {
      window.tp = window.tp || [];
      window.tp.push([
        "init", () => {
          window.tp.pianoId.loadExtendedUser({
            extendedUserLoaded: function (data) {
              if (window.location.pathname === "/members/login" || !data?.custom_field_values) return resolve(false);

              const fields = data.custom_field_values.map((field) => {
                return [field.field_name, field.value];
              });

              const fieldsObj = Object.fromEntries(fields);
              const { email_verified } = fieldsObj;
              const parsedPianoData = email_verified ? JSON.parse(email_verified) : null
              const value = Array.isArray(parsedPianoData) ? parsedPianoData[0] : null

              if (value === 'true' || value === 'legacy') {
                resolve(false);
              } else {
                resolve(true);
              }
            },
            formName: "emailVerification",
          });
        },
      ]);
    }
  });
}

export const isToOnboard = async (loginCookie: string, readerSubscriptionLevel: SubscriptionLevel): Promise<boolean> => {
  const tacCookie = Cookies.get("__tac");
  if (!loginCookie || tacCookie === undefined) return Promise.resolve(false);

  const decodedTacCookie: DecodedTacCookie = jwtDecode(tacCookie);
  const memberResource = "R792535";
  const startupResource = "RU6Q7JG";
  const proResource = "RDHCE1X";

  let resourceId;

  try {
    // A registered user does not have the following properties on their tac cookie
    const tacObj = JSON.parse(decodedTacCookie.sub)?.al
    if (tacObj) {
      const dynamicKey = Object.keys(tacObj)[0];
      resourceId = tacObj[dynamicKey][0].r;
    }
  } catch (e) {
    return false;
  }


  if (resourceId === proResource) {
    if (readerSubscriptionLevel !== 'pro') return false
  } else if (resourceId === startupResource) {
    if (readerSubscriptionLevel !== 'startup') return false
  } else if (resourceId === memberResource) {
    // The members' resource is legacy, and we should not onboard these users
    return false
  } else {
    if (readerSubscriptionLevel !== 'registered') return false
  }

  if (resourceId === memberResource) return false

  return await new Promise((resolve, _reject) => {
    if (typeof window !== "undefined") {
      window.tp = window.tp || [];
      window.tp.push([
        "init",
        function () {
          window.tp.pianoId.loadExtendedUser({
            extendedUserLoaded: function (data) {
              if (window.location.pathname === "/members/login" || !data?.custom_field_values) return

              const fields = data.custom_field_values.map((field) => {
                return [field.field_name, field.value];
              });

              const fieldsObj = Object.fromEntries(fields);

              const onboardingKey = { pro: "show_pro_onboard", startup: "show_startup_onboard", registered: "show_reg_onboard" }[readerSubscriptionLevel] as OnboardingCustomField

              const { [onboardingKey]: showOnboarding, } = fieldsObj;

              // Show onboarding if custom field is unset or set to true
              const toOnboard = showOnboarding === undefined || showOnboarding === "" || showOnboarding?.toLowerCase() === 'true'

              resolve(toOnboard);
            },
            formName: "onboardingForm",
          });
        },
      ]);
    }
  });
};

export const getPianoTags = (
  contentSubscriptionLevel?: ContentSubscriptionLevelType
): string[] => {
  const tags = ["is_ranking", "has_piano_id"];

  if (contentSubscriptionLevel === "is_member") {
    tags.push("is_member", "is_non_free");
  } else if (contentSubscriptionLevel === "is_pro") {
    tags.push("is_pro", "is_non_free");
  } else {
    tags.push("is_free");
  }

  return tags;
};

export const setConsentUserHash = async (_?: Event | undefined, userId?: string | null | undefined) => {
  const id = userId || window.tp?.pianoId?.getUser()?.uid
  const loginCookie = Cookies.get("__utp");
  const cookiebotUserHash = window.Cookiebot?.consentID

  if (id && loginCookie && cookiebotUserHash) {
    return await updateCustomFields(
      loginCookie,
      { cookiebotUserHash },
      id,
      "consent"
    );
  }
};