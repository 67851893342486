import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

import type { SubscriptionLevel } from "lib/types";

type SmemLevCookie = "reg" | "mem" | "pro";

const getSubscriptionLevelFromCookie = (
  cookieValue: SmemLevCookie,
  utpCookie: string
): SubscriptionLevel => {
  const subscriptionLevel = {
    reg: "registered",
    mem: "member",
    startup: "startup",
    pro: "pro",
  }[cookieValue];

  return (
    subscriptionLevel && utpCookie ? subscriptionLevel : "unregistered"
  ) as SubscriptionLevel;
};

function useReaderSubscriptionLevel(): SubscriptionLevel {
  // "sMemLev" is a cookie whose value is set by our Piano component. The Piano component
  // sets the cookie value as 'mem', 'pro' or 'reg' depending on what it receives from
  // Piano itself.
  const [cookies] = useCookies(["sMemLev", "__utp"]);

  const [subscriptionLevel, setSubscriptionLevel] = useState<SubscriptionLevel>(
    getSubscriptionLevelFromCookie(cookies.sMemLev, cookies.__utp)
  );

  useEffect(() => {
    setSubscriptionLevel(
      getSubscriptionLevelFromCookie(cookies.sMemLev, cookies.__utp)
    );
  }, [cookies]);

  return subscriptionLevel;
}

export default useReaderSubscriptionLevel;
