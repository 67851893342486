import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Mixpanel } from "lib/mixpanel/mixpanel";

import {
  showLoggedInComponents,
  hideLoggedInComponents,
  showLoggedOutComponents,
  hideLoggedOutComponents,
} from "./pianoHelpers";

declare global {
  interface Window {
    cancelLoginAutoReload: boolean;
  }
}

let pianoId;

const googleTagManagerPushUserId = (uid) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push({
    event: "login",
    userId: uid,
  });
};

type MembershipLevel = "member" | "pro" | "startup" | "free";

export const getMembershipLevel = (): Promise<MembershipLevel> => {
  return new Promise((resolve) => {
    window.tp.api?.callApi("/access/list", {}, function (response) {
      const accessList = response.data;
      // The user has access
      if (accessList) {
        accessList.forEach((access) => {
          const accessLevel = access.resource.rid;

          const isMember = accessLevel === "R792535";
          if (isMember) return resolve("member");

          const isStartup = accessLevel === "RU6Q7JG";
          if (isStartup) return resolve("startup");

          const isPro = accessLevel === "RDHCE1X";
          if (isPro) return resolve("pro");
        });

        return resolve("free");
      }
    });
  });
};

const setLoggedInState = (setCookie) => {
  return async (data) => {
    googleTagManagerPushUserId(data.user.uid);

    pianoId = data.user.uid;

    const membershipLevel = await getMembershipLevel();

    const membershipCookieValue = {
      member: "mem",
      startup: "startup",
      pro: "pro",
      // If the reader is neither 'member' or 'pro' after logging in they
      // are simply marked as registered with no additional access
      free: "reg",
    }[membershipLevel];

    setCookie("sMemLev", membershipCookieValue, {
      path: "/",
      // make sure it expires on the same time as __utp cookie
      expires: new Date(data.user.exp * 1000),
    });

    showLoggedInComponents();
    hideLoggedOutComponents();
  };
};

const setLoggedOutState = (removeCookie, mixpanel) => {
  return () => {
    mixpanel.track("Logout", {
      distinct_id: pianoId,
      "Page Path": window.location.pathname,
    });
    mixpanel.reset();
    pianoId = null;

    removeCookie("sMemLev", {
      path: "/",
    });
    showLoggedOutComponents();
    hideLoggedInComponents();
    window.location.href = "/";
  };
};

export const setPianoConsents = () => {
  const OPT_IN = "opt-in";
  const OPT_OUT = "opt-out";

  if (window.tp?.consent) {
    const statistics = window.Cookiebot?.consent?.statistics || false;
    const marketing = window.Cookiebot?.consent?.marketing || false;
    const preferences = window.Cookiebot?.consent?.preferences || false;
    const { setByPurpose, setAllPurposes } = window.tp.consent;

    // Reset
    setAllPurposes(OPT_OUT);

    // Audience Measurement
    setByPurpose("AM", statistics ? OPT_IN : OPT_OUT);
    // Content Personalization & Performance
    setByPurpose("CP", statistics && marketing ? OPT_IN : OPT_OUT);
    // Advertising
    setByPurpose("AD", marketing ? OPT_IN : OPT_OUT);
    // Personal Relationship
    setByPurpose("PR", marketing ? OPT_IN : OPT_OUT);
    // Data Layer
    setByPurpose(
      "DL",
      statistics && marketing && preferences ? OPT_IN : OPT_OUT
    );
  }
};

const Piano = ({
  pianoScriptUrl,
  mixpanelToken,
  tags = [],
  customVariables = null,
  contentAuthor = null,
  contentCreated = null,
}: {
  pianoScriptUrl: string;
  mixpanelToken: string;
  tags?: string[];
  customVariables?: { [key: string]: any } | null;
  contentAuthor?: string | null;
  contentCreated?: string | null;
}) => {
  const [cookies, setCookie, removeCookie] = useCookies([]);

  useEffect(() => {
    window.pdl = window.pdl || {};
    window.pdl.requireConsent = "v2";

    window.tp = window.tp || [];

    const mixpanel = new Mixpanel(mixpanelToken);

    window.tp.push([
      "init",
      function () {
        // When the script loads all logged out components should be displayed as default
        // TODO: check cookie if person is logged in and if not then display logged out components
        showLoggedOutComponents();

        // This ensures we opt the user out by default
        if (!window.tp.consent.getByPurpose()) {
          setPianoConsents();
        }

        window.tp.pianoId.init({
          // this event fires on every page load if user authorized and cookies are not expired
          loggedIn: setLoggedInState(setCookie),
          // this event fires when user clicks "Log out"
          // doesn't fire if user not-authorized
          loggedOut: setLoggedOutState(removeCookie, mixpanel),
        });
      },
    ]);

    window.tp.push([
      "addHandler",
      "loginSuccess",
      async function (data) {
        mixpanel.identify(data.params.uid);
        mixpanel.track("Login", {
          distinct_id: data.params.uid,
          "Page Path": window.location.pathname,
        });

        mixpanel.peopleSet({
          $name: `${data.params.firstName} ${data.params.lastName}`,
          $first_name: data.params.firstName,
          $last_name: data.params.lastName,
          $email: data.params.email,
        });

        // if a user has logged in (or registered from a content lock) we want the page to reload so that
        // the content block is removed.
        if (
          (data.source === "PIANOID" || // source === PIANOID will be set after someone clicks login from the navbar
            (!window.location.pathname.startsWith("/members/products") && // do not reload during purchase flow
              data.source === "OFFER")) && // source === OFFER set on reg/member block templates but also set on products template
          !window.cancelLoginAutoReload
        ) {
          window.location.reload();
        }
      },
    ]);

    window.tp.push([
      "addHandler",
      "registrationSuccess",
      function (data) {
        mixpanel.track("Registration", {
          distinct_id: data.user.sub,
          "Page Path": window.location.pathname,
        });
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push({
          event: "registrationSuccess",
        });
      },
    ]);

    window.tp.push([
      "setCustomVariable",
      "newletter_dismissed",
      // @ts-ignore
      cookies.sifted_registered === "dismissed",
    ]);
    window.tp.push([
      "setCustomVariable",
      "newletter_email_submitted",
      // @ts-ignore
      cookies.sifted_registered === "newsletter-signup",
    ]);
    window.tp.push([
      "setCustomVariable",
      "newletter_exit_popup_viewed",
      // @ts-ignore
      cookies.viewedOuibounceModal === "true",
    ]);
    window.tp.push([
      "addHandler",
      "beforeBrowserEvent",
      function (eventParams) {
        // @ts-ignore;
        window.latestpositionY = window.scrollY;
      },
    ]);
    window.tp.push([
      "addHandler",
      "checkoutClose",
      function (event) {
        // @ts-ignore;
        window.scrollTo(0, window.latestpositionY);
      },
    ]);

    if (tags) {
      window.tp.push(["setTags", tags]);
    }

    if (contentAuthor) {
      window.tp.push(["setContentAuthor", contentAuthor]);
    }

    if (contentCreated) {
      window.tp.push(["setContentCreated", contentCreated]);
    }

    if (customVariables) {
      Object.entries(customVariables).forEach(([key, value]) => {
        window.tp.push(["setCustomVariable", key, value]);
      });
    }

    window.tp.push(["setUsePianoIdUserProvider", true]);

    const attachPianoScript = () => {
      // @ts-ignore;
      if (!window.excludePiano) {
        const a = document.createElement("script");
        a.type = "text/javascript";
        a.defer = true;
        a.src = pianoScriptUrl;
        const b = document.getElementsByTagName("script")[0];
        // @ts-ignore;
        b.parentNode.appendChild(a, b);
      }
    };

    if (document.readyState === "complete") {
      attachPianoScript();
    } else {
      window.addEventListener("load", attachPianoScript);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default Piano;
