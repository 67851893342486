import { Mixpanel } from "lib/mixpanel/mixpanel";
import { MIXPANEL_TOKEN } from "./constants";
import { postRequest } from "./fetchers/fetcher";
import { TrackingAttributes, GTMEvent } from "./types";
import { getValueFromUserCookie } from "./userCookies";

export const trackGTMEvent = (GTMObject: GTMEvent): void => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  if (GTMObject.event) {
    (window as any).dataLayer.push({ ...GTMObject });
  }
};

export const postTalksEvent = ({
  fields,
}: {
  fields: TrackingAttributes;
}): Promise<any> => {
  let resolvePromise: { (arg0): void } | null = null;

  const promise = new Promise((resolve, reject) => {
    resolvePromise = resolve;
  });

  window.tp = window.tp || [];
  window.tp.push([
    "init",
    () => {
      const userCookie = window?.tp?.pianoId?.getToken();

      const params = new URLSearchParams({
        userCookie,
        ...fields,
      });

      const url = `/api/subscriptionUtils/tracking/talks?${params}`;

      resolvePromise?.(postRequest(url, fields));
    },
  ]);
  return promise;
};

export const postReportsDownload = ({
  fields,
}: {
  fields: TrackingAttributes;
}): Promise<any> => {
  let resolvePromise: { (arg0): void } | null = null;

  const promise = new Promise((resolve, reject) => {
    resolvePromise = resolve;
  });

  window.tp = window.tp || [];
  window.tp.push([
    "init",
    () => {
      const userCookie = window?.tp?.pianoId?.getToken();

      const params = new URLSearchParams({
        userCookie,
        ...fields,
      });

      const url = `/api/subscriptionUtils/tracking/reports?${params}`;

      resolvePromise?.(postRequest(url, fields));
    },
  ]);
  return promise;
};

export type UpdatePaywallCounterProps = {
  type: "ar" | "sc" | "br" | "re" | "ev";
  timestamp: number | string;
};

export const updatePaywallCounter = ({
  type,
  timestamp,
}: UpdatePaywallCounterProps): Promise<any> => {
  let resolvePromise: { (arg0): void } | null = null;

  const promise = new Promise((resolve, reject) => {
    resolvePromise = resolve;
  });

  window.tp = window.tp || [];
  window.tp.push([
    "init",
    () => {
      const userCookie = window?.tp?.pianoId?.getToken();

      const params = new URLSearchParams({
        userCookie,
        type,
        timestamp: timestamp.toString(),
      });

      const url = `/api/subscriptionUtils/tracking/paywallHit?${params}`;

      resolvePromise?.(postRequest(url, {}));
    },
  ]);

  return promise;
};

export const trackEventOnMixpanel = ({
  eventName,
  fields,
}: {
  eventName: string;
  fields: TrackingAttributes;
}): void => {
  const mixpanel = new Mixpanel(MIXPANEL_TOKEN);
  const userCookie = window?.tp?.pianoId?.getToken();
  const id = userCookie
    ? getValueFromUserCookie({ userCookie, key: "sub" })
    : null;

  if (id) {
    mixpanel.track(eventName, {
      distinct_id: id,
      ...fields,
    });
  }
};

export const slugify = (title: string) => {
  return title
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

export const getTimeZoneString = (
  dateTime: Date,
  timeZone = "Europe/London"
) => {
  const dateString = new Intl.DateTimeFormat("en-GB", {
    timeZone: timeZone,
    timeZoneName: "short",
  }).format(dateTime);
  const arr = dateString.split(" ");
  return arr[arr.length - 1];
};

export const setCssVariable = (name: string, value: string | null) => {
  document.documentElement.style.setProperty(name, value);
};

export function getHumanFriendlyNumber(n: number): string {
  const nf = new Intl.NumberFormat("en-GB");
  return nf.format(Number(n));
}

export const getDateFormattedNumeric = (timestamp: number | string) => {
  const date = new Date(timestamp).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  return date.replaceAll("/", ".");
};

// Output: Fri 21 Feb 2024
export const formattedDate = (
  date: Date,
  timezone = "Europe/London"
): string => {
  return new Intl.DateTimeFormat("en-GB", {
    timeZone: timezone,
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
  })
    .format(date)
    .replace(/,/g, "");
};

// Output: 14:00
export const formattedTime = (
  date: Date,
  timeZone = "Europe/London"
): string => {
  const formatted = new Intl.DateTimeFormat("en-GB", {
    timeZone: timeZone,
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  }).format(date);

  const [hours, minutes] = formatted.split(":");
  // Check if hours is a single digit, if so, prepend '0' to make it two digits
  const formattedHours = hours.length === 1 ? "0" + hours : hours;

  return `${formattedHours}:${minutes}`;
};

export const timeString = (time: Date, timeZone = "Europe/London") =>
  `${new Intl.DateTimeFormat("en-GB", {
    timeZone: timeZone,
    hour: "numeric",
    minute: "2-digit",
  }).format(time)}`;

// Output format: "Tuesday, 18 January 2022"
export const getDateStringWeekday = (dateTime: Date) => {
  return `${dateTime.toLocaleDateString("en-GB", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  })}`;
};

// Output format: "12:30→13:30 GMT / 13:30→14:30 CET"
export const upcomingStartEndString = (startTime: Date, endTime: Date) => {
  return `${formattedTime(startTime)}→${formattedTime(endTime)} ${getTimeZoneString(
    startTime
  )} / ${formattedTime(startTime, "Europe/Brussels")}→${formattedTime(
    endTime,
    "Europe/Brussels"
  )} ${getTimeZoneString(startTime, "Europe/Brussels")}`;
};

export const formatTime = (time: string | Date | number) => {
  // This regex changes 2016-09-17T00:15:00.000Z to 20160917T001500Z
  return new Date(time).toISOString().replace(/-|:|\.\d\d\d/g, "");
};

export const cleanSlugsFromDecodedQueryParameters = (
  rawSlugArray: string[]
) => {
  return rawSlugArray.map((rawSlug) => {
    return (
      new URL(rawSlug, "https://sifted.eu").pathname.split("/").pop() || rawSlug
    );
  });
};

export const ensureValidUrlForWebsiteUrl = (link: string) => {
  if (link.startsWith("http://") || link.startsWith("https://")) {
    return link;
  }

  if (link.includes(".")) {
    return `https://${link}`;
  }

  return link;
};

export * from "./sharedUtils/briefings";
